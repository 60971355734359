<template>
  <div>
    <div class="learningGroup-container" style="padding: 56px"><!-- learningGroup-list -->
      <ul class="learningGroup-list"><!-- learningGroup-item -->
        <li v-for="(item, index) in items" class="learningGroup-item" :key="index"><!-- learningGroup-card -->
          <article class="learningGroup-card"><!-- learningGroup-symbol -->
            <div class="learningGroup-content"><!-- content-row -->
              <div class="content-row"><!-- content-column -->
                <div class="content-column">
                  <h3 class="content-title"><span class="title">{{ item.lrnEvlNm }}</span></h3>
                  <p class="content-description">
                    <strong class="text">시험 기간 {{ timestampToDateFormat(item.evlBgngDt, 'yyyy.MM.dd hh:mm') }} ~ {{ timestampToDateFormat(item.evlEndDt, 'yyyy.MM.dd hh:mm') }}</strong>
                  </p>
                </div><!-- content-column -->
                <div class="content-column column-buttons">
                  <a href="javascript:" class="kb-btn kb-btn-primary" @click="setTargetEvaluation(index)"><span class="text">입장하기</span></a>
                </div><!-- content-column -->
              </div>
            </div>
          </article><!-- //learningGroup-card -->
        </li>
      </ul><!-- //learningGroup-list -->
    </div>
    <EvlModal
        v-if="target.toggle"
        v-model="target.toggle"
        :evl="targetEvl"
        @renewInit="renewInitEvl"
        @renewEnd="renewEndEvl"
    />
  </div>
</template>

<script>

import {useStore} from 'vuex';
import {ACT_INSERT_LRN_TY_EVL_DIST} from '@/store/modules/evaluation/evaluation';
import {ACT_GET_LEARN_OBJECT_TODAY_LIST} from '@/store/modules/learn/learn';
import {getItems, lengthCheck, timestampToDateFormat} from '@/assets/js/util';
import {reactive, ref} from 'vue';
import EvlModal from '@/components/evaluation/EvlModal';
import {isSuccess} from '@/assets/js/util';

export default {
  name: 'EvaluationSample',
  components: {EvlModal},
  setup(){
    const store = useStore();
    const items = ref([]);

    const target = reactive({
      toggle: false,
      index: -1
    });
    // const targetEvl = computed(() => {
    //   if(items.value[target.index]){
    //     return items.value[target.index]
    //   }
    //   return {}
    // });
    const targetEvl = ref({});

    const getCanTakeEvaluationList = () => {
      // store.dispatch(`evaluation/${ACT_GET_LRN_TY_EVL_DIST_LIST}`, {canTakeYn: 'Y'}).then(res => {
      // store.dispatch(`evaluation/${ACT_GET_KNOWLEDGE_LRN_TY_EVL_DIST_LIST}`, {}).then(res => {
      store.dispatch(`learn/${ACT_GET_LEARN_OBJECT_TODAY_LIST}`, {distCrseSn: 203070}).then(res => {
        console.log(getItems(res));
        if(lengthCheck(res)){
          items.value = getItems(res).filter(x => x.objSn === 4888);
        }
      });
    }

    const setTargetEvaluation = (index) => {
      targetEvl.value = items.value[index];
      // if(targetEvl.value.lrnTyEvlDistSn === 0){
        store.dispatch(`evaluation/${ACT_INSERT_LRN_TY_EVL_DIST}`, {
          distCrseSn: targetEvl.value.distCrseSn,
          lrnEvlMstSn: 4888,
          lrnRsltSn: 0
        }).then(res => {
          // console.log(res);
          if(isSuccess(res)){
            targetEvl.value.lrnTyEvlDistSn = res.lrnTyEvlDistSn;
            getCanTakeEvaluationList();
          }
        });
      // }
      setTimeout(() => {
        // console.log(targetEvl.value);
        target.toggle = true;
      }, 200);
    }

    // const getEvaluateList = () => {
    //   store.dispatch(`evaluation/${ACT_GET_KNOWLEDGE_LRN_TY_EVL_DIST_LIST}`).then(res => {
    //     if (lengthCheck(res)) {
    //       items.value = getItems(res).filter(x => x.lrnEvlMstSn === 4888);
    //     } else {
    //       items.value = [];
    //     }
    //   }).catch(e => {
    //     console.error(e);
    //   })
    // }

    const renewInitEvl = (evlBgngDt) => {
      items.value[target.index].evlExamBgngDt = evlBgngDt;
    }

    const renewEndEvl = () => {
      items.value[target.index].evlExamEndDt = new Date().getTime();
    }

    getCanTakeEvaluationList();

    return {
      items,
      target,
      targetEvl,
      setTargetEvaluation,
      renewInitEvl,
      renewEndEvl,
      timestampToDateFormat
    }
  }
};
</script>